import * as React from 'react';
import {IAPIContextProps, IAPIContextTypes} from './types';
import {APIService} from "../../services";
import {TripEvent} from "../../models/TripEvent";
import {Booking} from "../../models/Booking";
import {sort} from "fast-sort";

export const APIContext = React.createContext({} as IAPIContextTypes);

export const APIProvider = (props: IAPIContextProps): React.ReactElement => {

  const login = async (): Promise<any> => {
    return APIService.post("user")
  }

  const getEvents = async (): Promise<TripEvent[]> => {
    return new Promise(async (resolve) => {
      const events = await APIService.get<TripEvent[]>("event")

      const sortedEvents:TripEvent[] = sort(events).by([
        { asc: u => u.startDate },
      ]);

      resolve(sortedEvents);
    })
  }

  const addEvent = async (event: TripEvent): Promise<number> => {
    return APIService.post<number>("event", event);
  }

  const getEvent = async (eventId: number): Promise<TripEvent> => {
    return APIService.get<TripEvent>(`event/${eventId}`);
  }

  const updateEvent = async (event: TripEvent, eventId: number): Promise<void> => {
    return APIService.put<void>(`event/${eventId}`, event);
  }

  const deleteEvent = async (eventId: number): Promise<void> => {
    return APIService.delete<void>(`event/${eventId}`);
  }

  const getBookings = async (eventId: string | number): Promise<Booking[]> => {
    return APIService.get<Booking[]>(`booking/${eventId}`)
  }

  const addBooking = async (booking: Booking): Promise<number> => {
    return APIService.post<number>("booking", booking);
  }

  const deleteBooking = async (booking: Booking, reasonForCancellation: string): Promise<number> => {
    return APIService.delete<number>(`booking/${(booking as any).id}?reason=${reasonForCancellation}`);
  }

  const updateBooking = async (booking: Booking): Promise<number> => {
    return APIService.put<number>(`booking/${(booking as any).id}`, booking);
  }

  const getPdf = async (eventId:string): Promise<void> => {
    return new Promise( async (resolve) => {
      const response = await APIService.get<BlobPart>(`exportEventAsPdf/${eventId}`);

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `export-${eventId}.pdf`);
      document.body.appendChild(link);
      link.click();

      if(link.parentNode) {
        link.parentNode.removeChild(link);
      }

      resolve();
    })
  }

  return (
    <APIContext.Provider value={{
      login: login,
      getEvents: getEvents,
      addEvent: addEvent,
      getEvent: getEvent,
      deleteEvent: deleteEvent,
      updateEvent: updateEvent,
      getBookings: getBookings,
      addBooking: addBooking,
      deleteBooking: deleteBooking,
      updateBooking: updateBooking,
      getPdf: getPdf
    }}>
      {props.children}
    </APIContext.Provider>
  );
};

export const APIConsumer = APIContext.Consumer;

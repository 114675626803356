import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {ILanguageSelectorTypes} from "./ILanguageSelectorTypes";
import {Language} from "../../models/Language";

export const LanguageSelector = (types: ILanguageSelectorTypes): React.ReactElement => {
  return (
    <ToggleButtonGroup
      color="info"
      size={"small"}
      value={types.language}
      exclusive={true}
      onChange={(event) => {
        types.onChange((event.target as any).value as Language)
      }}>
      <ToggleButton value="da-DK">Dansk</ToggleButton>
      <ToggleButton value="en-US">Engelsk</ToggleButton>
      <ToggleButton value="de-DE">Tysk</ToggleButton>
    </ToggleButtonGroup>
  );
}
import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {ISuccessDialogProps} from "./types";
import {Button, DialogActions, DialogContent, DialogContentText} from "@mui/material";

export const SuccessDialog = (props: ISuccessDialogProps) => {
  return (
    <Dialog onClose={props.onRedirect} open={props.show}>
        <DialogTitle id="alert-dialog-title">
          Succes
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.onAdd && (
            <Button variant="contained" color={"primary"} onClick={props.onAdd}>
              Tilføj tilmeldinger
            </Button>
          )}
          <Button variant="contained" color={"success"} onClick={props.onRedirect} autoFocus>
            Til oversigten
          </Button>
        </DialogActions>
      </Dialog>
  );
}
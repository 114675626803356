import {Navigate, Route, Routes,} from "react-router-dom";
import * as React from 'react';
import {Dashboard} from "../Dashboard";
import {Events} from "../Events";
import {Login} from "../Login";
import {Logout} from "../Logout";
import {RestrictedPage} from "../../components";
import {CreateEvent} from "../Event/CreateEvent";
import {EditEvent} from "../Event/EditEvent";

export const routes = (
  <Routes>
    <Route path="/" element={
      <RestrictedPage>
        <Dashboard/>
      </RestrictedPage>
    }/>

    <Route path="/event" element={
      <RestrictedPage>
        <Events/>
      </RestrictedPage>
    }/>

    <Route path="/event/create" element={
      <RestrictedPage>
        <CreateEvent/>
      </RestrictedPage>
    }/>

    <Route path="/event/edit/:id" element={
      <RestrictedPage>
        <EditEvent/>
      </RestrictedPage>
    }/>

    <Route path="/login" element={<Login/>}/>
    <Route path="/logout" element={<Logout/>}/>

    <Route path="*" element={<Navigate to="/login"/>}/>
  </Routes>
)

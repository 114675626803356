import {Navigate, useLocation} from "react-router-dom";
import React from "react";

export const Required = ({children}) => {
  let location = useLocation();

  if (!localStorage.getItem("Authorization")) {
    return <Navigate to="/login" state={{from: location}} replace/>;
  } else {
    return children;
  }
}
import {Container, LinearProgress, List, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material"
import React from "react"
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import {Header} from "../../components";
import {APIContext} from "../../contexts";
import {TripEvent} from "../../models/TripEvent";
import {DateTime} from "luxon";
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import {useNavigate} from "react-router-dom";

export const Dashboard = (): React.ReactElement => {
  const navigate = useNavigate();

  const {getEvents} = React.useContext(APIContext);
  const [events, setEvents] = React.useState<TripEvent[] | undefined>()
  const [loading, setLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    getEvents().then(events => {
      setTimeout(() => {
        setEvents(events)
        setLoading(false);
      }, 1000)
    });
  }, [])

  return (
    <div className={"container"}>
      <Header
        subTitle={"Oversigt over aktuelle ture"}
        title={"Dashboard"}/>

      <Container>
        {loading && <LinearProgress/>}
        {!loading && (
          <Card>
            <List sx={{width: '100%', bgcolor: 'background.paper'}}>
              {events && events.map((event: TripEvent, index: number) => {
                const startTime = DateTime.fromMillis(event.startDate as number).toFormat("ff");
                const endTime = DateTime.fromMillis(event.endDate as number).toFormat("ff");
                const now = DateTime.now();
                const isNowOrFuture: boolean = event.endDate >= now.toMillis();
                const isAnnulled: boolean = (event && event.deletedAt !== null && event.deletedAt !== undefined && event.deletedAt > 0);

                if (isNowOrFuture) {
                  return (
                    <ListItemButton
                      onClick={() => {
                        navigate(`/event/edit/${(event as any).ID}`)
                      }}
                      key={index}>
                      <ListItemAvatar>
                        <Avatar>
                          <DirectionsBoatFilledIcon/>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={isAnnulled ? ("Aflyst: " + event.title) : event.title}
                        secondary={`${(event.bookedSeats === null || event.bookedSeats === undefined) ? 0 : event.bookedSeats} tilmeldt${event.bookedSeats === 1 ? "" : "e"}, ${startTime} - ${endTime}`}/>
                    </ListItemButton>
                  );
                } else {
                  return null;
                }
              })}
            </List>
          </Card>
        )}
      </Container>
    </div>
  )
}
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {IDataTableProps} from "./types";
import {TripEvent} from "../../models/TripEvent";
import {DateTime} from "luxon";
import {useNavigate} from "react-router-dom";

interface Column {
  id: 'title' | 'startDate' | 'endDate' | 'seats' | 'priceInDkk' | 'priceInEur';
  label: string;
  minWidth?: number;
  align?: 'right';
}

const columns: readonly Column[] = [
  {id: 'title', label: 'Titel', minWidth: 170},
  {id: 'startDate', label: 'Start', minWidth: 100},
  {id: 'endDate', label: 'Slut', minWidth: 100},
  {id: 'seats', label: 'Pladser i alt', minWidth: 100},
  {id: 'seatsAvailable', label: 'Ledige pladser', minWidth: 100},
  {id: 'bookedSeats', label: 'Antal tilmeldinger', minWidth: 100},
  {id: 'priceInDkk', label: 'Pris / DKK', minWidth: 100},
  {id: 'priceInEur', label: 'Pris / EURO', minWidth: 100},
];

export const DataTable = (props: IDataTableProps) => {
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dkkFormatter = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
  });
  const eurFormatter = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'EUR',
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{width: '100%', marginTop: "24px", overflow: 'hidden'}}>
      <TableContainer sx={{maxHeight: 440}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column: Column, index: number) => (
                <TableCell
                  key={index}
                  align={column.align}
                  style={{minWidth: column.minWidth}}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data && props.data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((event: TripEvent, key: number) => {
                const startTime = DateTime.fromMillis(event.startDate).toFormat("ff");
                const endTime = DateTime.fromMillis(event.endDate).toFormat("ff");

                const priceDkkFormatted = dkkFormatter.format(event.priceInDkk)
                const priceEurFormatted = eurFormatter.format(event.priceInEur)
                // const now = DateTime.now();
                // const isNowOrFuture: boolean = event.endDate >= now.toMillis();

                return (
                  <TableRow
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      navigate(`/event/edit/${(event as any).ID}`)
                    }}
                    hover role="checkbox" tabIndex={-1} key={key}>
                    <TableCell>
                      {event.title}
                    </TableCell>
                    <TableCell>
                      {startTime}
                    </TableCell>
                    <TableCell>
                      {endTime}
                    </TableCell>
                    <TableCell>
                      {event.seats}
                    </TableCell>
                    <TableCell>
                      {event.seatsAvailable}
                    </TableCell>
                    <TableCell>
                      {(event.bookedSeats === undefined || event.bookedSeats === null) ? 0 : event.bookedSeats}
                    </TableCell>
                    <TableCell>
                      {priceDkkFormatted}
                    </TableCell>
                    <TableCell>
                      {priceEurFormatted}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={"Rækker pr. side"}
        labelDisplayedRows={({from, to, count}) => {
          return `${from}–${to} af ${count !== -1 ? count : `mere end ${to}`}`;
        }}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.data ? props.data.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
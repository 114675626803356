import React from "react"
import {Container, Typography} from "@mui/material";
import {IDashboardTypes} from "../../domain/Dashboard/types";
import "./header.scss"

export const Header = (types: IDashboardTypes) => {
  return (
    <Container className="header">
      <div>
        <Typography variant="h4" component="h1">
          {types.title}
        </Typography>
        <Typography variant="h5" component="h5" className="header--subtitle">
          {types.subTitle}
        </Typography>
      </div>

      <div className="header--actions">
        {types.actions}
      </div>
    </Container>
  )
}
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  CircularProgress,
  DialogContentText,
  FormControl, FormControlLabel,
  Grid, Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import {Booking} from "../../models/Booking";
import {green} from "@mui/material/colors";
import {IEditBookingDialogTypes} from './IEditBookingDialogTypes';

export const EditBookingDialog = (props: IEditBookingDialogTypes) => {
  const [firstNameError, setFirstNameError] = React.useState<boolean>(false);
  const [lastNameError, setLastNameError] = React.useState<boolean>(false);
  const [emailError, setEmailError] = React.useState<boolean>(false);
  const [phoneError, setPhoneError] = React.useState<boolean>(false);
  const [reasonForCancellation, setReasonForCancellation] = React.useState<"missing_payment" | "bad_weather" | "too_few_signups">("bad_weather");
  const [tempBooking, setTempBooking] = React.useState<Booking>(props.booking ? props.booking : {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    contactManager: false,
    eventId: "",
    bookingId: "",
    seats: 1,
    note: ""
  });

  React.useEffect(() => {
    if (props.booking) {
      setTempBooking(props.booking)
    }
  }, [props.booking])

  const [showValidateDeleteDialog, setShowValidateDeleteDialog] = React.useState<boolean>(false);

  const resetStates = () => {
    setShowValidateDeleteDialog(false);
    setFirstNameError(false);
    setLastNameError(false);
    setPhoneError(false)
    setEmailError(false)
  }

  const isFormValid = (): boolean => {
    let firstNameValid: boolean = true;
    let lastNameValid: boolean = true;
    let emailValid: boolean = true;
    let phoneValid: boolean = true;

    if (!tempBooking.firstName || tempBooking.firstName.length === 0) {
      firstNameValid = false;
    }

    if (!tempBooking.lastName || tempBooking.lastName.length === 0) {
      lastNameValid = false;
    }

    if (!tempBooking.email || tempBooking.email.length === 0) {
      emailValid = false;
    }

    if (!tempBooking.phone || tempBooking.phone.length === 0) {
      phoneValid = false;
    }

    return firstNameValid && lastNameValid && emailValid && phoneValid;
  }

  const validateFirstName = (firstName: string): void => {
    let firstNameValid: boolean = false;
    if (firstName && firstName.length > 0) {
      firstNameValid = true;
    }

    setFirstNameError(!firstNameValid);
  }

  const validateLastName = (lastName: string): void => {
    let lastNameValid: boolean = false;
    if (lastName && lastName.length > 0) {
      lastNameValid = true;
    }

    setLastNameError(!lastNameValid);
  }

  const validateEmail = (email: string): void => {
    let emailValid: boolean = false;
    if (email && email.length > 0) {
      emailValid = true;
    }

    setEmailError(!emailValid);
  }

  const validatePhone = (phone: string): void => {
    let phoneValid: boolean = false;
    if (phone && phone.length > 0) {
      phoneValid = true;
    }

    setPhoneError(!phoneValid);
  }

  const updateBooking = (prop: string, value: string): void => {
    const newBooking = {...tempBooking};
    newBooking[prop] = value;
    setTempBooking(newBooking)
  }

  return (
    <>
      <Dialog
        open={showValidateDeleteDialog}>
        <DialogTitle>
          Sletning af tur
        </DialogTitle>
        <DialogContent>
          <Typography>
            Du er ved at slette en booking fra en tur. Er du sikker? Handlingen kan ikke fortrydes men du kan vælge grunden til aflysningen herunder.
          </Typography>

          <FormControl>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={reasonForCancellation}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setReasonForCancellation((event.target as any).value);
                }}
                name="radio-buttons-group">
              <FormControlLabel value="bad_weather" control={<Radio />} label="Dårligt vejr" />
              <FormControlLabel value="too_few_signups" control={<Radio />} label="For få tilmeldte" />
              <FormControlLabel value="missing_payment" control={<Radio />} label="Manglende betaling" />
            </RadioGroup>
          </FormControl>

          <DialogActions>
            <Button
              variant={"contained"}
              onClick={() => {
                props.onCancel();
                setShowValidateDeleteDialog(false);
              }}>
              Nej, annullér
            </Button>
            <Button
              color={"error"}
              variant={"contained"}
              onClick={() => {
              if (props.booking) {
                setShowValidateDeleteDialog(false);
                props.onDelete(props.booking, reasonForCancellation)
              }
            }}>
              Ja, slet!
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={props.show}
        onClose={() => {
          props.onCancel();
          resetStates();
        }}
        maxWidth={"sm"}>
        <DialogTitle>Rediger tilmelding</DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <DialogContentText>
                <Typography>
                  Her kan du redigere en tilmelding til turen.
                </Typography>
              </DialogContentText>
            </Grid>

            <Grid item={true} xs={12}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    disabled={props.deleteLoading}
                    value={tempBooking.firstName}
                    onChange={(event) => {
                      validateFirstName(event.target.value);
                      updateBooking("firstName", event.target.value)
                    }}
                    error={firstNameError}
                    autoFocus
                    margin="dense"
                    label="Fornavn"
                    helperText={firstNameError ? "Du mangler at udfylde fornavnet" : "Den tilmeldtes fornavn"}
                    type="text"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    disabled={props.deleteLoading}
                    helperText={lastNameError ? "Du mangler at udfylde efternavnet" : "Den tilmeldtes efternavn"}
                    error={lastNameError}
                    value={tempBooking.lastName}
                    onChange={(event) => {
                      validateLastName(event.target.value)
                      updateBooking("lastName", event.target.value)
                    }}
                    margin="dense"
                    label="Efternavn"
                    type="text"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    disabled={props.deleteLoading}
                    value={tempBooking.email}
                    onChange={(event) => {
                      validateEmail(event.target.value);
                      updateBooking("email", event.target.value)
                    }}
                    error={emailError}
                    helperText={emailError ? "Du mangler at skrive en email adresse" : "Den tilmeldtes email adresse"}
                    margin="dense"
                    label="Email"
                    type="email"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    disabled={props.deleteLoading}
                    value={tempBooking.phone}
                    onChange={(event) => {
                      validatePhone(event.target.value);
                      updateBooking("phone", event.target.value)
                    }}
                    margin="dense"
                    label="Telefon"
                    error={phoneError}
                    helperText={phoneError ? "Du mangler at skrive et telefonnummer" : "Den tilmeldtes telefonnummer, med landekode, e.g. 0045 eller +45"}
                    type="tel"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    disabled={true}
                    value={tempBooking.seats}
                    margin="dense"
                    label="Pladser"
                    helperText={"Antal pladser"}
                    type="number"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    multiline={true}
                    rows={2}
                    minRows={2}
                    maxRows={2}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={tempBooking.note}
                    margin="dense"
                    label="Note"
                    helperText={"Note fra kunden"}
                    type="text"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Box sx={{m: 1, position: 'relative', marginRight: "auto"}}>
            <Button
              disabled={!isFormValid() || props.deleteLoading || props.editLoading}
              variant={"contained"}
              color={"error"}
              onClick={() => {
                setShowValidateDeleteDialog(true);
              }}>Slet</Button>
            {props.deleteLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>

          <Button onClick={() => {
            props.onCancel();
            resetStates();
          }} variant={"contained"} color={"primary"}>Annullér</Button>
          <Box sx={{m: 1, position: 'relative'}}>
            <Button
              disabled={!isFormValid() || props.deleteLoading || props.editLoading}
              variant={"contained"}
              color={"success"}
              onClick={() => {
                if (isFormValid()) {
                  props.onApply(tempBooking);
                }
              }}>Gem ændringerne</Button>
            {props.editLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
import React from "react";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {Card, CardContent, CircularProgress, Container, Grid, TextField} from "@mui/material";
import {IEventFormTypes} from "./types";
import "./event-form.scss"

export const EventForm = (types: IEventFormTypes): React.ReactElement => {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      {types.loading && (
        <Container>
          <Card style={{position: "relative"}}>
            <CardContent>
              <div className={"form-backdrop"}>
                <CircularProgress/>
              </div>
            </CardContent>
          </Card>
        </Container>
      )}

      <Container>
        <Grid container={true} spacing={2}>
          {!types.loading && (
            <>
              <Grid item={true} xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          helperText="Indtast turens navn"
                          fullWidth={true}
                          label={types.language === "en-US" ? "Engelsk titel" : (types.language === "de-DE" ? "Tysk" : "Dansk") + " titel"}
                          value={(types.language === "en-US" ? types.event.title_en : (types.language === "de-DE" ? types.event.title_de : types.event.title)) || ''}
                          onChange={(changeEvent) => {
                            switch (types.language) {
                              case "da-DK":
                                types.onChange({...types.event, title: changeEvent.target.value})
                                break;
                              case "de-DE":
                                types.onChange({...types.event, title_de: changeEvent.target.value})
                                break;
                              case "en-US":
                                types.onChange({...types.event, title_en: changeEvent.target.value})
                                break;
                            }
                          }}
                          variant="outlined"
                          type={"text"} autoFocus={true}/>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item={true} xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          value={(types.language === "en-US" ? types.event.description_en : (types.language === "de-DE" ? types.event.description_de : types.event.description)) || ''}
                          onChange={(changeEvent) => {
                            switch (types.language) {
                              case "da-DK":
                                types.onChange({...types.event, description: changeEvent.target.value})
                                break;
                              case "de-DE":
                                types.onChange({...types.event, description_de: changeEvent.target.value})
                                break;
                              case "en-US":
                                types.onChange({...types.event, description_en: changeEvent.target.value})
                                break;
                            }
                          }}
                          helperText="Skriv en beskrivelse til turen. Denne er synlig i kalenderen"
                          rows={10}
                          fullWidth={true}
                          label={types.language === "en-US" ? "Engelsk beskrivelse" : (types.language === "de-DE" ? "Tysk" : "Dansk") + " beskrivelse"}
                          variant="outlined"
                          multiline={true}
                          type={"text"}/>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item={true} xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          value={types.event.seats}
                          onChange={(changeEvent) => {
                            types.onChange({
                              ...types.event,
                              seats: Number(changeEvent.target.value),
                            })
                          }}
                          helperText="Indtast antal pladser på turen"
                          fullWidth={true}
                          label="Pladser"
                          type={"number"}
                          variant="outlined"/>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          value={types.event.seatsAvailable}
                          onChange={(changeEvent) => {
                            types.onChange({
                              ...types.event,
                              seatsAvailable: Number(changeEvent.target.value),
                            })
                          }}
                          helperText="Indtast antal ledige pladser på turen"
                          fullWidth={true}
                          label="Ledige pladser"
                          type={"number"}
                          variant="outlined"/>
                      </Grid>


                      <Grid item xs={6}>
                        <TextField
                          value={types.event.priceInDkk}
                          onChange={(changeEvent) => {
                            types.onChange({...types.event, priceInDkk: Number(changeEvent.target.value)})
                          }}
                          helperText="Pris pr. tilmelding i DKK"
                          fullWidth={true}
                          label="Pris i DKK"
                          type={"number"}
                          variant="outlined"/>
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          value={types.event.priceInEur}
                          onChange={(changeEvent) => {
                            types.onChange({...types.event, priceInEur: Number(changeEvent.target.value)})
                          }}
                          helperText="Pris pr. tilmelding i EURO"
                          fullWidth={true} label="Pris i EURO"
                          type={"number"}
                          variant="outlined"/>
                      </Grid>
                      <Grid item xs={3}>
                        <DateTimePicker
                          inputFormat="dd/MM/yyyy HH:mm"
                          ampm={false}
                          ampmInClock={false}
                          renderInput={(props) => <TextField {...props} />}
                          label="Start"
                          value={types.event.startDate}
                          onChange={(newValue) => {
                            if (newValue) {
                              types.onChange({...types.event, startDate: newValue})
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <DateTimePicker
                          inputFormat="dd/MM/yyyy HH:mm"
                          ampm={false}
                          ampmInClock={false}
                          renderInput={(props) => <TextField {...props} />}
                          label="Slut"
                          value={types.event.endDate}
                          onChange={(newValue) => {
                            if (newValue) {
                              types.onChange({...types.event, endDate: newValue})
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </LocalizationProvider>
  );
}
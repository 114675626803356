import React from "react";
import {useNavigate} from "react-router-dom";
import {Backdrop, CircularProgress} from "@mui/material";

export const Logout = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    localStorage.clear();

    setTimeout(() => {
      navigate("login");
    }, 3000)
  }, [])

  return (
    <Backdrop
      sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
      open={true}>
      <CircularProgress color="inherit"/>
    </Backdrop>
  );
}
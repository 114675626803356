import React from "react";
import {Header, LanguageSelector, SuccessDialog} from "../../../components";
import {Fab} from "@mui/material";
import {DateTime} from "luxon";
import {APIContext} from "../../../contexts";
import {TripEvent} from "../../../models/TripEvent";
import {useNavigate} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {Language} from "../../../models/Language";
import {EventForm} from "../Shared";

export const CreateEvent = () => {
  const {addEvent} = React.useContext(APIContext);
  const navigate = useNavigate();

  const [selectedLanguage, setSelectedLanguage] = React.useState<Language>('da-DK');
  const [event, setEvent] = React.useState<TripEvent>({
    title: "",
    title_de: "",
    title_en: "",
    description: "",
    description_de: "",
    description_en: "",
    seats: 16,
    seatsAvailable: 16,
    priceInDkk: 3900,
    priceInEur: 540,
    startDate: DateTime.now(),
    endDate: DateTime.now()
  });

  const [success, setSuccess] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [successEventId, setSuccessEventId] = React.useState<number | undefined>();

  const submitForm = async () => {
    if (event.startDate && event.endDate && isFormValid()) {
      const tempEvent: TripEvent = {...event};
      tempEvent.startDate = (event.startDate as DateTime).toMillis();
      tempEvent.endDate = (event.endDate as DateTime).toMillis();

      setLoading(true);
      try {
        const eventId = await addEvent(tempEvent);
        setSuccessEventId(eventId)
        setSuccess(true);
      } catch (e) {
      }
    }
  }

  const isFormValid = (): boolean => {
    return event.title.length > 0 && event.description.length > 0 && event.seats > 0 && event.priceInEur > 0 && event.priceInDkk > 0;
  }

  return (
    <div className="container">
      <SuccessDialog
        description={"Turen blev oprettet. Du kan vælge om du vil tilbage til oversigten eller tilføje tilmeldinger til turen."}
        onAdd={() => {
          navigate(`/event/edit/${successEventId}?addBookings=true`);
        }}
        onRedirect={() => {
          navigate("/event");
        }}
        show={success}/>

      <Header
        title="Opret tur"
        actions={(
          <LanguageSelector
            language={selectedLanguage}
            onChange={(language: Language) => {
              setSelectedLanguage(language);
            }}
          />
        )}
      />

      <EventForm
        loading={false}
        language={selectedLanguage}
        event={event}
        onChange={(event: TripEvent) => {
          setEvent(event)
        }}
      />

      <Fab
        disabled={!isFormValid() || loading}
        onClick={submitForm}
        size={"large"}
        variant={"extended"}
        color={"success"}
        style={{position: "absolute", bottom: "48px", right: "48px"}}
        aria-label="add">
        <AddIcon sx={{mr: 1}}/>
        Opret tur
      </Fab>
    </div>
  );
}